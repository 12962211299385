.input-container,
.outline-input-container {
	position: relative;
}

.label-input .text-input {
	transition: all 0.25s ease-out;
	padding: 0px 4px;
}
.outline-input-container .text-input {
	transition: all 0.25s ease-out;
	padding: 0;
}

input:focus + .label-input .text-input,
:not(input[value=""]) + .label-input .text-input {
	transform: translate(0, -100%);
	background-color: white;
	width: max-content;
}

.input-container textarea::placeholder {
	opacity: 0;
}
.input-container input {
	width: 100%;
	height: 39px;
	outline: none !important;
	padding-left: 0px;
}
input:disabled {
	background-color: transparent;
	color: rgba(0, 0, 0, 0.38) !important;
	border-style: dotted;
	border-color: rgba(0, 0, 0, 0.38);
}
.input-container label {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-55%);
	transition: all 0.3s ease-out;
	pointer-events: none;
}

textarea {
	width: 100%;
	resize: none !important;
	outline: none !important;
}
.input-container textarea:focus + label,
.input-container textarea:not(:placeholder-shown) + label {
	top: 0;
	background: #fff;
	width: max-content;
	outline: none !important;
	padding: 0px 7px;
}
.error-message {
	text-align: left;
}
.englease-rtl {
	.input-container label {
		left: unset;
		right: 10px;
	}
	.error-message {
		text-align: right;
	}
}

.outline-input {
	border: 0px;
	border-bottom: 1px solid var(--color-muteTxt);
}
.outline-input-container input {
	width: 100%;
	height: 39px;
	outline: none !important;
	padding-left: 0px;
}

.outline-input-container label {
	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-55%);
	transition: all 0.3s ease-out;
	pointer-events: none;
}
.input-field {
	.input-container input,
	select {
		width: 100%;
		height: 3.438rem;
	}
	.input-container,
	.outline-input-container,
	select {
		position: relative;
	}

	.label-input .text-input,
	select {
		transition: all 0.25s ease-out;
		padding: 0px 4px;
	}
	.outline-input-container .text-input,
	select {
		transition: all 0.25s ease-out;
		padding: 0;
	}
}
.englease-rtl {
	.outline-input-container label {
		right: 0px;
	}
	.input-field {
		select {
			width: 100% !important;
		}
	}
}
