.student-learning {
	&__holder {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--color-muteBorder);
		color: var(--color-muteBorder);

		&-icon {
			width: 16px;
			height: 16px;

			@media screen and (min-width: 576px) {
				width: 21px;
				height: 21px;
			}
		}
	}
	&__button {
		min-width: 170px;
		text-align: center;
		justify-content: center;
		@media screen and (min-width: 768px) {
			width: 100%;
		}
	}
	&__level {
		background-color: var(--color-white);
		width: 100%;
		&--holder {
			display: grid;
			@media screen and (min-width: 768px) {
				grid-template-columns: 1fr auto;
				grid-template-rows: auto auto;
				&-title {
					grid-column: 1/2;
					grid-row: 1/2;
				}
				&-btn {
					grid-column: 2/3;
					grid-row: 1/3;
				}
				&-progress {
					grid-column: 1/2;
					grid-row: 2/3;
				}
			}
		}
	}
	&__stepper {
		&--lines {
			@media screen and (max-width: 768px) {
				position: absolute;
				left: 50%;
				bottom: -90px;
				width: 17px;
				margin: 16px 0px;
				height: 73px;
			}
		}
		&:nth-last-of-type(1) &--lines {
			width: 0px;
		}
		&--number {
			&-active {
				height: 73px;
				animation-name: any;
				animation-duration: 1s;
				@keyframes any {
					0% {
						height: 37px;
					}
					100% {
						height: 73px;
					}
				}
			}
			&-non-active {
				height: 37px;
			}
		}
		&--steps-number {
			position: absolute;
			width: 24px;
			top: -16px;
			left: -16px;
			height: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			padding: 16px;
		}
		&--holder {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 54px;
			height: 54px;
		}
	}
	&__appearance {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px 10px;
		background-color: var(--color-black);
	}
	&__lines {
		&--active {
			.MuiStepContent-root {
				border-left: 0px solid var(--color-Lavender);
				@media screen and (min-width: 768px) {
					border-left: 17px solid var(--color-Lavender);
				}
			}
		}
		&--disactive {
			.MuiStepContent-root {
				border-left: 0px solid var(--color-mute);
				@media screen and (min-width: 768px) {
					border-left: 17px solid var(--color-mute);
				}
			}
		}
	}
}
.englease-rtl {
	.student-learning {
		&__stepper {
			&--steps-number {
				left: 36px;
			}
		}
		&__lines {
			&--active {
				.MuiStepContent-root {
					border-right: 0px solid var(--color-Lavender);
					@media screen and (min-width: 768px) {
						border-right: 17px solid var(--color-Lavender);
					}
				}
			}
			&--disactive {
				.MuiStepContent-root {
					border-right: 0px solid var(--color-mute);
					@media screen and (min-width: 768px) {
						border-right: 17px solid var(--color-mute);
					}
				}
			}
		}
		&__button{
			min-width: 170px;
		}
	}
}
