.support {
	background: linear-gradient(to bottom, #ffffff 225px, #f2f2f2 20%);
	padding-bottom: 70px;
	&__holder {
		margin: 0 3rem;
		padding: 24px 0px;
		@media screen and (min-width: 992px) {
			margin: unset;
			padding: 24px 0;
			border: 1px solid #e8e9eb;
		}
	}
	&__section {
		padding: 24px 0 0px;
		justify-content: center;
    display: flex;
		width: 100%;
		@media screen and (min-width: 992px) {
			padding: 52px 0 52px;
		}
	}
&__txt-area{
	width: 100%;
  height: 103px;
	resize:	 none !important;
	outline: none !important;
}
	&__btn {
		width: 100%;
		padding: 0.75rem 0;
		border: 0;
		border-radius: 6px;
	}
	&__message {
		&--display {
			display: flex;
			align-items: center;
		}
	}
}

