.app {
	min-height: 100vh !important;
}
.content-wrapper {
	height: 100vh !important;
}

#zmmtg-root {
	display: none;
}

html,
body {
	min-width: unset !important;
	overflow: unset !important;
}
