.englease {
	&__bg {
		&--main {
			background-color: var(--color-main) !important;
			&--20 {
				background-color: var(--color-Lavender) !important;
			}
		}

		&--sup {
			background-color: var(--color-sup) !important;
		}
		&--smoke {
			background-color: var(--color-smoke) !important;
		}

		&--secondary {
			background-color: var(--color-secondary) !important;
			&--20 {
				background-color: rgb(230, 242, 255) !important;
			}
		}

		&--black {
			background-color: var(--color-black) !important;
		}

		&--white {
			background-color: var(--color-white) !important;
		}

		&--light {
			background-color: var(--color-light) !important;
		}

		&--mute {
			background-color: var(--color-mute) !important;
		}

		&--muteTxt {
			background-color: var(--color-muteTxt) !important;
		}
		&--inherit {
			background-color: var(--color-inherit) !important;
		}

		&--orange {
			background-color: var(--color-orange) !important;
		}

		&--success {
			background-color: var(--color-success) !important;
			&--20 {
				background-color: rgba(53, 183, 121, 0.2) !important;
			}
		}
		&--error {
			background-color: var(--color-error) !important;
		}
	}

	/* Backgrounds ===END */

	&__txt {
		&--main {
			color: var(--color-main) !important;
		}
		&--nav-main {
			color: var(--nav-main) !important;
		}

		&--sup {
			color: var(--color-sup) !important;
		}
		&--smoke {
			color: var(--color-smoke) !important;
		}

		&--secondary {
			color: var(--color-secondary) !important;
		}

		&--color-nav {
			color: var(--color-nav) !important;
		}

		&--black {
			color: var(--color-black) !important;
		}

		&--white {
			color: var(--color-white) !important;
		}

		&--light {
			color: var(--color-light) !important;
		}

		&--mute {
			color: var(--color-mute) !important;
		}

		&--muteTxt {
			color: var(--color-muteTxt) !important;
		}
		&--gray {
			color: var(--color-gray) !important;
		}

		&--success {
			color: var(--color-success) !important;
		}

		&--orange {
			color: var(--color-orange) !important;
		}
		&--error {
			color: var(--color-wrong) !important;
		}
	}

	/* Text Color ===END */
}
