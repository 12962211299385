@import "./styles/_custom";
@import "./styles/_global";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.englease-rtl {
	direction: rtl;
	text-align: right;
}

.spinner {
	width: 300px;
	height: 300px;
	margin: 100px auto;
	border-radius: 100%;
	-webkit-animation: sk-scaleout 1s infinite ease-in-out;
	animation: sk-scaleout 1s infinite ease-in-out;
}

.GTMClassIframe {
	height: "0";
	width: "0";
	display: none;
	visibility: hidden;
}