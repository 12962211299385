.ET-logo-size {
    min-width: 117px;
    height: 22px;
}

.ET-btn-holder {
    max-width: 120px;
}
.dropdown-toggle::after{
    content: none !important;
}