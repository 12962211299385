.reset-password {
  align-items: center;
  text-align: center;
  padding: 0px;
  @media screen and (min-width: 992px) {
    padding: 10% 112px 0 0;
    display: flex;
    justify-content: flex-end;
    height: 80%;
  }

  &__image {
    width: 100%;
    height: 100%;
    @media screen and (min-width: 992px) {
      position: fixed;
      top: 0px;
      left: -22%;
      background-size: cover;
      background-repeat: no-repeat;
      mix-blend-mode: normal;
      background-image: url("../../../public/assets/images/forgetPassword.jpg");
      z-index: -1;
    }
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    margin-left: 0rem;
    min-height: 100vh;
    @media screen and (min-width: 992px) {
   border: 1px solid #ededed;
      margin-left: 3rem;
      min-height: 100%;
    }
  }
  &__form-logo {
		width: 14.625rem;
    height: 3.25rem;
    margin-top: 38px;
  }
  &__form-title {
    margin: 24px 24px 42px;
  }
  &__form-holder {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 350px);
    justify-content: center;
    padding: 0 4rem;
    @media screen and (min-width: 992px) {
      justify-content: space-between;
    }
  }
 
  &--login {
    display: flex;
    justify-content: center;
    margin: auto 0rem 1.5rem;
    @media screen and (min-width: 992px) {
      margin-top: 8.5rem;
    }
  }

  &__btn {
    width: 100%;
    padding: 0.75rem 0 ;
    border: 0;
    border-radius: 6px;
  }

 
  &__password-icon {
    position: absolute;
    top: 13px;
    right: 16px;
  }
  &__message{
    &--display{
      display: flex ;
      align-items: center;
      height: 100px;
    }
    &--close{
      position: absolute;
      top: 0;
      right: 0;
      margin: 8px 8px 0px;
      opacity: .5;
    }
  }
}

.englease-rtl {
  .reset-password {
    @media screen and (min-width: 992px) {
      padding-left: 112px;
    }

    &__form-wrapper {
      margin-right: 0rem;
      @media screen and (min-width: 992px) {
        margin-right: 3rem;
        margin-left: 0rem;
      }
    }
    &__password-icon {
      right: unset;
      left: 16px;
    }

    &__image {
      left: 22%;
      transform: rotateY(-180deg);
    }
    &__message{
      &--close{
        right: unset;
        left: 0;
      }
    }
  }
}
