.countdown {
	display: flex;
	width: fit-content !important;
	align-items: center;
	border: 1px solid #f5f5f5;
	border-radius: 18px;

	&--divider {
		width: 1px;
		height: 16px;
		background: #e8e9eb;
		margin: 0 6px;
	}
}
