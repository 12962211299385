.assessment-result {
	text-align: center;
	font-weight: 400;
	font-style: normal;

	&__title {
		color: #000;
		font-weight: 600;
		font-size: 28.0637px;
		line-height: 33px;
	}

	&__celebration-icon {
		&::after {
			// content: "🥳";
			content: "🎉";
			font-weight: 800;
			font-size: 65px;
			line-height: 110px;
		}
	}

	&__sad-icon {
		&::after {
			content: "\1F614";
			font-weight: 800;
			font-size: 65px;
			font-style: normal;
			line-height: 110px;
		}
	}

	&__score {
		color: var(--color-main) !important;
	}

	&__sub-title {
		// color: #424b5a;
		font-weight: 800;
		font-size: 24px;
		line-height: 28px;
	}

	&__level-type {
		color: #c2d1d9;
		font-size: 21.0478px;
		line-height: 33px;
	}

	&__current-level {
		color: var(--color-main) !important;
		margin: 2rem 0;
		font-size: 30px;
		font-weight: 600;
		line-height: 32px;
	}

	&__message {
		color: #4d4d4d;
		font-weight: 800;
		font-size: 24px;
		line-height: 28px;
	}

	&__retakeTest {
		border: 2px solid #4c0b86 !important;
		color: var(--color-main) !important;
		min-width: 142px;
		margin-bottom: 10px;
		margin-top: 50px;
	}

	&__showResult {
		display: block;
		margin-top: 10px;
		color: var(--color-main) !important;
		text-decoration: underline !important;
	}
}
