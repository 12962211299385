@import "/src/styles/_global";

.about {
  &__title {
    font-size: 60px;

    &--active {
      color: red;
    }
  }

  &__holder {
    border: var(--border-radius) solid #000;
  }
}
