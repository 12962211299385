.message{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  &__renewal{
    flex-direction: column;
      margin-bottom: 16px;
      align-items: flex-start;
    @media screen and (min-width: 768px)  {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0px;
    }
    @media screen and (min-width: 992px) {
      margin-bottom: 0px;
    }
  }
}