.hct {
	padding: 1rem 1rem 0 1rem;
	@media screen and (min-width: 992px) {
		padding: 3rem 3rem 0 3rem;
	}

	&__title {
		color: #4c0086;
		text-align: center;
		font-size: 40px;
		font-weight: 700;
		text-transform: capitalize;
	}

	&__sub-title {
		color: #000;
		font-size: 28px;
		font-weight: 500;
	}

	&__question {
		color: #4c0086;
		font-size: 24px;
		font-weight: 500;
	}

	&__img-wrapper {
		margin-bottom: 2rem;
	}

	&__question-wrapper {
		margin-bottom: 3rem;
	}

	&__message {
		&--display {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	&__form-wrapper {
		display: flex;
		padding: 2rem;
		border-radius: 5px;
		flex-direction: column;
		background-color: #fff;
		border: 1px solid #ededed;
	}
}

.englease-rtl {
	.hct {
		direction: ltr;
		text-align: left;
	}
}
