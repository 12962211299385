.ET-logo-size {
	min-width: 117px;
	height: 22px;
}

.ET-btn-holder {
	max-width: 120px;
}
.dropdown-toggle::after {
	content: none !important;
}

.logout {
	cursor: pointer;
	margin-right: 0.625rem;
}

.dropdown-menu.show {
	width: 100vw;
	padding: 10px;
	@media screen and (min-width: 576px) {
		width: fit-content;
	}
}

#dropdown-basic:focus {
	outline: none;
	box-shadow: none;
}

.dropdown-menu-lang {
	display: flex !important;
	align-items: center;
	padding-left: 25px !important;
	margin-bottom: 0.5rem;
}

.dropdown-menu-lang.iET-Global.englease__bg--contain {
	background-position: left !important;
	height: 19px;
	margin-right: 10px;
}
.dropdown-item.active , .dropdown-item:active , .dropdown-item:hover{
	background-color: var(--color-main) !important ;
	color: var(--color-white) !important;
}

.header{
	&__nav{
			color: var(--color-nav) !important;
		}
}



.englease-rtl {
	.dropdown-menu-lang {
		padding-right: 25px !important;
	}
	.dropdown-menu-lang.iET-Global.englease__bg--contain {
		background-position: right !important;
	}
	.logout {
		margin-right: 0;
		margin-left: 0.625rem;
	}
}

.menuToggle {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3px;

 &__input-holder {
	align-items: center;
	position: absolute;
	cursor: pointer;
	opacity: 0;
	z-index: 1;
}
 p{
	&::before{content: ""; width: 10px;}
	margin: 0px;
	width: 21px;
	height: 3px;
	background: var(--color-black);
	border-radius: 4px;
  -webkit-transition: all .3s cubic-bezier(.08,.81,.87,.71);
  -moz-transition: all .3s cubic-bezier(.08,.81,.87,.71);
  -ms-transition: all .3s cubic-bezier(.08,.81,.87,.71);
  -o-transition: all .3s cubic-bezier(.08,.81,.87,.71);
  transition: all .3s cubic-bezier(.08,.81,.87,.71);
}
.span1 {
  transform-origin: 4px 0px;
}
.span3 {
  transform-origin: bottom right;
}

&__input-holder:checked ~  {
  background-color: #000;
	.span1{
		transform: rotate(45deg) translate(3px);
	}
	.span2{
		transform: rotate(495deg) translate(1px);   
	}
	.span3{
		transform: rotate(45deg) translate(1px);;
		opacity: 0;
	}
}
}
