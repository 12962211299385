.Renewal {
	&__image {
		opacity: 0.25;
		width: 10rem;
		height: 10rem;
		background-size: 100% 100%;
	}
	&__text {
		text-align: center;
		@media screen and (min-width: 992px) {
			width: 52%;
		}
	}
	&__decoration {
		text-decoration: underline !important;
	}
}
.englease-rtl {
	.Renewal {
		&__text {
			text-align: center;
			@media screen and (min-width: 992px) {
				width: 60%;
			}
		}
	}
}
