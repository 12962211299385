.imET-engleaselogo {
	background-image: url("../../public/assets/images/logooc.webp");
}
.imET-profilevictor {
	background-image: url("../../public/assets/images/Profilevictor.svg");
}
.imET-linesPattern {
	background-image: url("../../public/assets/images/dashboardPattern.svg");
	@media only screen and (max-width: 1199px) {
		background-image: none;
	}
}
.imET-dashboardPattern {
	background-image: url("../../public/assets/images/dashboardPattern.svg");
}
.imET-Renewal {
	background-image: url("../../public/assets/images/Renew.png");
}