.login {
	align-items: center;
	text-align: center;
	padding: 0;
	@media screen and (min-width: 992px) {
		padding: 10% 112px 0 0;
		display: flex;
		justify-content: flex-end;
		height: 80%;
	}

	&__image {
		width: 100%;
		height: 100%;
		@media screen and (min-width: 992px) {
			position: fixed;
			top: 0;
			left: -22%;
			background-size: cover;
			background-repeat: no-repeat;
			mix-blend-mode: normal;
			background-image: url("../../../public/assets/images/Login.jpg");
			z-index: -1;
		}
	}

	&__form-wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		background-color: #fff;
		border-radius: 5px;
		margin-left: 0;
		min-height: 100vh;
		@media screen and (min-width: 992px) {
			border: 1px solid #ededed;
			margin-left: 3rem;
			min-height: 100%;
		}
	}
	&__form-logo {
		margin-top: 24px;
		width: 14.625rem;
		height: 3.25rem;
		@media screen and (min-width: 992px) {
			margin-top: 38px;
		}
	}
	&__form-title {
		margin: 24px 24px 42px;
	}
	&__form-holder {
		padding: 0 1.5rem;
		width: 100%;
		@media screen and (min-width: 992px) {
			padding: 0 4rem;
		}
	}

	&__btn {
		width: 100%;
		padding: 0.75rem 0;
		border: 0;
		border-radius: 6px;
		margin: auto 0 1rem;
		@media screen and (min-width: 992px) {
			margin: 1rem 0;
		}
	}

	&__password-icon {
		position: absolute;
		top: 13px;
		right: 16px;
	}

	&--language {
		margin: 1rem;
	}
	&--signup {
		margin: auto 0 1rem;
		@media screen and (min-width: 992px) {
			margin: 1rem 0;
		}
	}
	&--forgetPassword {
		text-align: end;
	}
	&__message {
		&--display {
			display: flex;
			align-items: center;
			height: 100%;
		}
		&--close {
			position: absolute;
			top: 0;
			right: 0;
			margin: 8px 8px 0;
			opacity: 0.5;
		}
	}
	&--error {
		color: #d32f2f;
		height: 18.91px;
		text-align: start;
	}
	&__label {
		transform-origin: left top !important;
	}
}

.englease-rtl {
	.login {
		@media screen and (min-width: 992px) {
			padding-left: 112px;
		}
		&__form-wrapper {
			margin-right: 0;
			@media screen and (min-width: 992px) {
				margin-left: 0;
			}
		}
		&__password-icon {
			right: unset;
			left: 16px;
		}
		&__image {
			left: 22%;
			transform: rotateY(-180deg);
		}
		&__message {
			&--close {
				right: unset;
				left: 0;
			}
		}
		&__label {
			transform-origin: right top !important;
			background-color: #ffff;
			padding: 0px 0px 0px 5px !important;
			right: 25px;
		}
	}
}
