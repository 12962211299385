.self-survey {
	@media screen and (min-width: 992px) {
		background: linear-gradient(to bottom, #ffffff 25%, #f2f2f2 20%);
		border: 1px solid #e8e9eb;
	}
	&__holder {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding: 24px 0 24px;
		@media screen and (min-width: 992px) {
			border: 1px solid #e8e9eb;
		}
	}
	&__section {
		text-align: left;
	}
	&__button {
		width: 100%;
		@media (min-width: 576px) {
			width: max-content;
		}
	}
	input[type="radio"] {
		align-self: center;
	}

	ol li {
		font-weight: bolder;
		list-style-type: decimal !important;
		list-style-position: outside !important;
	}

	ol {
		padding-left: 1rem;
	}

	label {
		vertical-align: text-top;
	}
}

.englease-rtl {
	.self-survey {
		direction: ltr;
		&__section {
			text-align: left;
		}
		input[type="radio"] {
			align-self: center;
		}
	}
}
