.questionsAnswer {
	@media screen and (min-width: 992px) {
		background: linear-gradient(to bottom, #ffffff 25%, #f2f2f2 20%);
		border: 1px solid #e8e9eb;
	}

	&__media-attachment {
		width: 70px;
		height: 70px;

		@media screen and (min-width: 992px) {
			width: 100px;
			height: 100px;
		}
	}
	&__holder {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding: 24px 0 48px;
		@media screen and (min-width: 992px) {
			border: 1px solid #e8e9eb;
		}
	}
	&__section {
		direction: ltr;
		text-align: left;
		box-shadow: rgb(0 0 0 / 10%) 0px 5px 8px, rgb(0 0 0 / 10%) 0px 1px 2px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px 16px 0;
		@media screen and (min-width: 576px) {
			padding: 24px 40px 0px;
		}
	}
	&__input {
		width: 100%;
		padding: 18px;
	}
}
