// set It In Fonts File As Global Classes
.dashboard {
	&__assessment {
		&--holder {
			width: 100%;
			background-color: #FED8D4;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			@media screen and (min-width: 768px) {
				width: 50%;
			}
		}
	}
	&__image {
		background-repeat: no-repeat;
		background-size: 58%;
		background-position: 40% 61%;

		@media screen and (min-width: 768px) {
			background-size: 300px;
			background-position: 54% 1%;
		}
	}
	&__classes-holder{
		display: flex;
		flex-direction: column;
		gap: 24px 0px;
		@media screen and (min-width: 992px) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__title {
		width: 100%;
		text-align: center;
		justify-content: center;
		padding: 0 0 1rem 0;
		@media screen and (min-width: 768px) {
			padding: 2rem 1.25rem 2rem 6rem;
			text-align: start;
		}
	}
}
.englease-rtl{
	.dashboard{
		&__title {
			@media screen and (min-width: 768px) {
				padding: 2rem 6rem 2rem 1.25rem;
			}
		}
	}
}