.student-hero {
	&__holder {
		min-height: 139px;
	}
	&__count-down {
		width: max-content;
		margin-right: 1.3rem ;
		@media screen and (min-width: 992px) {
			margin-right: 0.5rem;
		}
	}
	&__time-holder {
		&--time {
			height: 48px;
			max-width: 50px;
    	width: 50px;
		}
	}
}
.englease-rtl {
	.student-hero {
		&__time-wrapper {
			direction: ltr;
		}

		&__count-down {
			margin-right: unset;
			margin-left: 0.5rem;
		}
		&__time-holder {
			&--time {
				text-align: center;
			}
		}
	}
}
